/* eslint-disable */
import { generate_pdf_content as generatePDFContent } from './pdf_template'
import { componentStatusList } from './support_list'
import { api, checklistApi } from '../plugins/axios_settings'
export default {
  downloadReportHelper (checklistId, formId, formName, token, isBooking, recordId, checklistHasPdfTemplate) {
    return new Promise((resolve, reject) => {
      try {
        let listOfFields = []
        let headers = { headers: { 'X-AccessToken': token } }
        checklistApi.get(`formproperties/get_by_form/${formId}`, headers).then((response) => {
          let list = response.data
          for (let item of list) {
            if ([3, 4, 5, 18, 10, 12].includes(item.type)) { //  list[i].type === 3 || list[i].type === 4 || list[i].type === 5 || list[i].type === 18 => simplified
              item.default_value = item.default_value ? JSON.parse(item.default_value) : null
            }
            if (item.type === 18) {
              let defaultValue = item.default_value
              if (defaultValue.grid_data && defaultValue.grid_data.labels) item.showSum = this.checkAndGetSumRowData(defaultValue.grid_data.labels)
            }
          }
          listOfFields = list
          checklistApi.get(`checklists/${checklistId}`, headers).then((result) => {
            let fields = result.data
            let data = fields.data ? JSON.parse(fields.data) : null
            fields = data
            // var checklistHasPdfTemplate = true // change the flag to actual prop
            if (checklistHasPdfTemplate && checklistHasPdfTemplate !== 'null') {
              var pdfMappingData = this.constructTemplateMappingData(listOfFields, fields)
              if (isBooking) {
                api.post(`/bookingchecklists/generate_pdf/${recordId}`, pdfMappingData).then((response) => {
                  if (response && response.data) window.open(`${api.defaults.baseURL}files/download?fileName=${response.data}`, '_blank')
                  resolve() // it will come inside http request
                })
              } else {
                api.post(`checklists/generate_pdf/${recordId}`, pdfMappingData).then((response) => {
                  if (response && response.data) window.open(`${api.defaults.baseURL}files/download?fileName=${response.data}`, '_blank')
                  resolve() // it will come inside http request
                })
              }
            } else {
              let model = { content: '' }
              model.content = this.generatingContentForPDF(formName, listOfFields, fields)
              checklistApi.post(`checklists/generate_pdf/${formId}/${checklistId}`, model, headers).then((checklistResponse) => {
                window.open(`${checklistApi.defaults.baseURL}files/download?fileName=${checklistResponse.data}`, '_blank')
                resolve()
              })
            }
          })
        })
      } catch (err) {
        reject(err)
      }
    })
  },
  generatingContentForPDF (formName, listOfFields, fields) {
    return generatePDFContent(formName, listOfFields, fields, componentStatusList)
  },
  checkAndGetSumRowData (labels) {
    let obj = { showSumRow: false, sumColIndexes: [] }
    labels.forEach((label, index) => {
      let { field } = label
      if (field && field.typedata && field.typedata.isshowsum) {
        obj.showSumRow = true
        obj.sumColIndexes.push(index)
      }
    })
    return obj
  },
  constructTemplateMappingData (fields, data) {
    let mappingResult = []
    /* let fieldTypeNumberWithText = {
      1: 'textfield',
      2: 'textarea',
      3: 'checkbox',
      4: 'radio',
      5: 'select',
      6: 'datetime', // it is actually data - in pdfmapper there is only datetime
      7: 'time',
      11: 'divider',
      13: 'signature',
      14: 'panel',
      16: 'html',
      17: 'label'
    } */
    data = data || {}
    fields.forEach(element => {
      const defaultValue = element.default_value ? typeof element.default_value === 'string' ? JSON.parse(element.default_value) : element.default_value : {}
      let obj = { name: element.name, type: element.type || 0 }
      var keyValueList = []
      switch (element.type) {
        case 3:
          obj.value = data[element.name] || ''
          obj.options = defaultValue.options || []
          obj.selecttype = defaultValue.checkboxtype || 2 // 1 single, 2 multiple
          if (obj.selecttype === 2) {
            let selectedCheckboxValues = (data[element.name] && data[element.name].length) ? data[element.name] : []
            /* construct key value pair for all options of checkbox with true false value set based on the selected values */
            defaultValue.options.map((option) => {
              let selectboxObj = {
                name: `${element.name}[${option.value}]`,
                value: selectedCheckboxValues.includes(option.value),
                selecttype: 1,
                type: element.type
              }
              keyValueList.push(selectboxObj)
            })
          }
          break
        case 4:
        case 5:
          obj.value = data[element.name] || ''
          obj.options = defaultValue.options || []
          if (element.type === 5) obj.ismultiselect = defaultValue.is_multiselect
          break
        case 11: // divider
        case 17: // label
          obj.value = element.label || ''
          break
        case 14: // panel
          obj.value = element.label || ''
          break
        case 16: // html
          obj.value = defaultValue.html_content || ''
          break
        default:
          obj.value = data[element.name] || ''
          break
      }
      mappingResult.push(obj)
      if (keyValueList && keyValueList.length) mappingResult = [...mappingResult, ...keyValueList]
    })
    return mappingResult
  }
}
